import React from 'react'

//meta data
import {
  pageData as page,
  websiteTexts,
} from '../../../../src/data/Top10RealEstateWebsites/2022'
import meta from '../../../hooks/Top10RealEstateWebsites/2022'

//style
import * as cx from './Top10RealEstateWebsites.module.scss'
import Top10RealEstateWebsite from '../../../components/pages/top-10-real-estate-websites'

const Top10RealEstateWebsite2022 = () => {
  const {
    BannerBG,
    BannerImage1,
    Item1Slide1,
    Item1Slide2,
    Item2Slide1,
    Item2Slide2,
    Item3Slide1,
    Item3Slide2,
    Item4Slide1,
    Item4Slide2,
    Item5Slide1,
    Item5Slide2,
    Item6Slide1,
    Item6Slide2,
    Item7Slide1,
    Item7Slide2,
    Item8Slide1,
    Item8Slide2,
    Item9Slide1,
    Item9Slide2,
    Item10Slide1,
    Item10Slide2,
  } = meta()

  const items = {
    item1: { slide1: Item1Slide1, slide2: Item1Slide2 },
    item2: { slide1: Item2Slide1, slide2: Item2Slide2 },
    item3: { slide1: Item3Slide1, slide2: Item3Slide2 },
    item4: { slide1: Item4Slide1, slide2: Item4Slide2 },
    item5: { slide1: Item5Slide1, slide2: Item5Slide2 },
    item6: { slide1: Item6Slide1, slide2: Item6Slide2 },
    item7: { slide1: Item7Slide1, slide2: Item7Slide2 },
    item8: { slide1: Item8Slide1, slide2: Item8Slide2 },
    item9: { slide1: Item9Slide1, slide2: Item9Slide2 },
    item10: { slide1: Item10Slide1, slide2: Item10Slide2 },
  }

  const bannerDetails = {
    heading: '2022 Top 10\nReal Estate Websites',
    subHeading: 'Be Inspired by These Trend-Setting Realtor Websites!',
    styles: {
      imageStyle: cx.bannerImage1,
      textHeadingStyle: cx.bannerTextHeading,
      textSubheadingStyle: cx.bannerTextSubheading,
    },
    image: BannerImage1,
  }

  return (
    <Top10RealEstateWebsite
      pageDetails={{
        pageTitle: page.title,
        breadcrumbs: page.breadcrumbs,
        pageUri: page.pageUri,
      }}
      banner={{
        bg: BannerBG,
        heading: bannerDetails.heading,
        subHeading: bannerDetails.subHeading,
        styles: bannerDetails.styles,
        image: bannerDetails.image,
      }}
      websites={{ type: 'A', images: items, texts: websiteTexts }}
    />
  )
}

export default Top10RealEstateWebsite2022
