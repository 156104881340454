export const pageData = {
  title: '2022 Top 10 Real Estate Websites',
  breadcrumbs: [
    { text: 'Home', url: '/' },
    {
      text: '2022 Top 10 Real Estate Websites',
      url: '/top-10-real-estate-websites/2022',
    },
  ],
  uri: '/top-10-real-estate-websites/2022',
}

export const websiteTexts = [
  {
    title: 'The Oppenheim Group',
    subtitle: 'West Hollywood, CA',
    description:
      'With over $1 billion in total sales, $300 million in active listings, and the hit Netflix show “Selling Sunset” under its belt, The Oppenheim Group is undoubtedly at the top of its game. And that’s immediately obvious with their website, which boasts a sleek theme, intuitive navigation, dynamic animations, and interactive features — exactly what you’d expect from one of L.A.’s most revered real estate groups.',
  },
  {
    title: 'The Oppenheim Group',
    subtitle: 'West Hollywood, CA',
    description:
      'With over $1 billion in total sales, $300 million in active listings, and the hit Netflix show “Selling Sunset” under its belt, The Oppenheim Group is undoubtedly at the top of its game. And that’s immediately obvious with their website, which boasts a sleek theme, intuitive navigation, dynamic animations, and interactive features — exactly what you’d expect from one of L.A.’s most revered real estate groups.',
  },
  {
    title: 'The Oppenheim Group',
    subtitle: 'West Hollywood, CA',
    description:
      'With over $1 billion in total sales, $300 million in active listings, and the hit Netflix show “Selling Sunset” under its belt, The Oppenheim Group is undoubtedly at the top of its game. And that’s immediately obvious with their website, which boasts a sleek theme, intuitive navigation, dynamic animations, and interactive features — exactly what you’d expect from one of L.A.’s most revered real estate groups.',
  },
  {
    title: 'The Oppenheim Group',
    subtitle: 'West Hollywood, CA',
    description:
      'With over $1 billion in total sales, $300 million in active listings, and the hit Netflix show “Selling Sunset” under its belt, The Oppenheim Group is undoubtedly at the top of its game. And that’s immediately obvious with their website, which boasts a sleek theme, intuitive navigation, dynamic animations, and interactive features — exactly what you’d expect from one of L.A.’s most revered real estate groups.',
  },
  {
    title: 'The Oppenheim Group',
    subtitle: 'West Hollywood, CA',
    description:
      'With over $1 billion in total sales, $300 million in active listings, and the hit Netflix show “Selling Sunset” under its belt, The Oppenheim Group is undoubtedly at the top of its game. And that’s immediately obvious with their website, which boasts a sleek theme, intuitive navigation, dynamic animations, and interactive features — exactly what you’d expect from one of L.A.’s most revered real estate groups.',
  },
  {
    title: 'The Oppenheim Group',
    subtitle: 'West Hollywood, CA',
    description:
      'With over $1 billion in total sales, $300 million in active listings, and the hit Netflix show “Selling Sunset” under its belt, The Oppenheim Group is undoubtedly at the top of its game. And that’s immediately obvious with their website, which boasts a sleek theme, intuitive navigation, dynamic animations, and interactive features — exactly what you’d expect from one of L.A.’s most revered real estate groups.',
  },
  {
    title: 'The Oppenheim Group',
    subtitle: 'West Hollywood, CA',
    description:
      'With over $1 billion in total sales, $300 million in active listings, and the hit Netflix show “Selling Sunset” under its belt, The Oppenheim Group is undoubtedly at the top of its game. And that’s immediately obvious with their website, which boasts a sleek theme, intuitive navigation, dynamic animations, and interactive features — exactly what you’d expect from one of L.A.’s most revered real estate groups.',
  },
  {
    title: 'The Oppenheim Group',
    subtitle: 'West Hollywood, CA',
    description:
      'With over $1 billion in total sales, $300 million in active listings, and the hit Netflix show “Selling Sunset” under its belt, The Oppenheim Group is undoubtedly at the top of its game. And that’s immediately obvious with their website, which boasts a sleek theme, intuitive navigation, dynamic animations, and interactive features — exactly what you’d expect from one of L.A.’s most revered real estate groups.',
  },
  {
    title: 'The Oppenheim Group',
    subtitle: 'West Hollywood, CA',
    description:
      'With over $1 billion in total sales, $300 million in active listings, and the hit Netflix show “Selling Sunset” under its belt, The Oppenheim Group is undoubtedly at the top of its game. And that’s immediately obvious with their website, which boasts a sleek theme, intuitive navigation, dynamic animations, and interactive features — exactly what you’d expect from one of L.A.’s most revered real estate groups.',
  },
  {
    title: 'The Oppenheim Group',
    subtitle: 'West Hollywood, CA',
    description:
      'With over $1 billion in total sales, $300 million in active listings, and the hit Netflix show “Selling Sunset” under its belt, The Oppenheim Group is undoubtedly at the top of its game. And that’s immediately obvious with their website, which boasts a sleek theme, intuitive navigation, dynamic animations, and interactive features — exactly what you’d expect from one of L.A.’s most revered real estate groups.',
  },
]
